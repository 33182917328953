.center {
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.th, .td {
    border: 1px solid black;
    padding: 8px;
}

.orders-list.w-full.h-full {
    max-height: calc(100vh - 120px);
    overflow: scroll;
    margin-top: 60px;
}
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    border: 1px solid #ccc;
}

.frame {
    position: absolute;
    border: 1px solid #000;
    background-color: #f0f0f0;
    cursor: move;
}
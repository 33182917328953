.settings-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.settings-container h2 {
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
}

.settings-container h3 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #444;
}

.announcements-section, .popup-settings-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}

.add-announcement {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.announcement-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.announcements-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.announcements-table th,
.announcements-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.announcements-table th {
    background-color: #f5f5f5;
    font-weight: 600;
}

.popup-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-group label {
    font-weight: 500;
    color: #555;
}

.popup-input,
.popup-textarea {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.popup-textarea {
    min-height: 100px;
    resize: vertical;
}

.preview-image {
    max-width: 200px;
    margin-top: 10px;
    border-radius: 4px;
}

.add-btn,
.save-btn {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
}

.delete-btn {
    background-color: #f44336;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.2s;
}

.add-btn:hover,
.save-btn:hover {
    background-color: #45a049;
}

.delete-btn:hover {
    background-color: #da190b;
}

.file-input {
    padding: 8px 0;
}

.color-picker-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.remove-color-btn {
    padding: 5px 10px;
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.remove-color-btn:hover {
    background-color: #cc0000;
} 